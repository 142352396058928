import React, {useState} from "react";
import classnames from "classnames";
import { Button, Typography, Tooltip } from "@material-ui/core";
import ButtonTwo from "../../../components/Button/Button.js";
import { useStyles } from "./style.js";
import { ReactComponent as NewPumaLogo } from "../../../assets/icons/MangaPuma.svg";
import { ReactComponent as ScopeIcon } from "../../../assets/icons/ScopeIcon.svg";
import { ReactComponent as PawIcon } from "../../../assets/icons/Paw.svg";
import { ReactComponent as QuestionMarkTooltip } from "../../../assets/icons/question-mark-tooltip.svg";
import { ReactComponent as LookAlikeIcon } from "../../../assets/icons/LookalikeSearchIcon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash.svg";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LoadScopeDialog from "../../Results/components/PreviousScopes/components/LoadScopeDialog";
import DeleteSearchDialog from "../../Results/components/PreviousScopes/components/DeleteSearchDialog";
import TableBody from "@material-ui/core/TableBody";
import { useSearches } from "../../../common/useSearches.js";
import {actions as searchActions} from "../../../store/search/slice";
import { useSelector, useDispatch } from "react-redux";
import redirect from "react-router/es/Redirect";
import {Link} from "react-router-dom";
import {useRouterHook} from "../../../common/useRouterHook";

const ScopeSelect = () => {
    const classes = useStyles();
	const [previewScopes, setPreviewScopes] = useState(false);
	const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
	const [openDeleteSearchModal, setOpenDeleteSearchModal] = useState(false);
	const [selectedScope, setSelectedScope] = useState(null);
	const { openPage } = useRouterHook();
	
	const dispatch = useDispatch();
	
	const {
        getRecentSearches: {
            data: searches,
        },
        deleteSearch,
    } = useSearches();
	
	const loadScope = () => {
		handleSubmitSearch();
	}
	
	const handleRemoveSearch = () => {
		if(selectedScope){
			deleteSearch.mutate(selectedScope.id)
		}
	}
	
	const handleSubmitSearch = e => {
		// mixpanelTrackEvent("Company search", {
		//     Page: page,
		//     Regions: selectedRegions,
		//     Countries: selectedCountries,
		//     Industries: selectedSectors.map(sector => sector.name),
		//     Subindustries: selectedSubsectors,
		//     EditMode: !!isEditMode,
		//     Type: "Regular",
		// });
		// handleCaptchaChange();
		
		// redirect to deep dive page
		openPage(`/results?sid=${selectedScope.id}`);
	};

    return (
        <div className={classnames([classes.mainWrapper])}>
            <div className={classnames([classes.titleWrapper])}>
                <div className={classnames([classes.image])}>
                    <NewPumaLogo className={classes.newPumaLogo} />
                </div>
                <div className={classnames([classes.titleTextWrapper])}>
                    <Typography
                        variant="h6"
                        className={classnames([classes.title])}>
                        <strong>Choose Your Approach</strong>
                    </Typography>
                </div>
	            <div className={classnames([classes.scopePreviewButtons])}>
		            
		            <ButtonTwo
		                className={classes.getSummaryBtn}
		                onClick={() => setPreviewScopes(!previewScopes)}
		                variant="quaternary"
		            >
		                {previewScopes ? "New Scope" : "Previous Scopes"}
		            </ButtonTwo>
	            </div>
                <Tooltip
                    title={
                        <Typography
                            color="white"
                            variant="body2"
                            className={classes.infoTooltipText}>
                            In the Assisted Approach, you are guided through 5
                            steps which will result in an auto-generation of
                            your Scope Prompt, as a concise, 150-word summary
                            integrating your professional context, technological
                            innovations, challenges, user needs, and practical
                            applications. This assists you and PUMA to fetch the
                            most relevant data from our database, for an
                            analysis well aligned with your specific needs. 
                            <br></br><br></br>
                            Alternatively, the Unassisted Approach grants you
                            complete autonomy to conduct your analysis using the
                            available resources, ideal for those who prefer
                            direct control over their strategic exploration.
                        </Typography>
                    }
                    arrow>
                    <QuestionMarkTooltip
                        className={classnames([classes.scopeSelectTooltip])}
                    />
                </Tooltip>
            </div>
	        {
				!previewScopes && (
			        <div className={classnames([classes.contentWrapper])}>
				        <div className={classnames([classes.searchWrapper])}>
					        <div style={{ marginTop: "30px" }}>
						        <Typography
							        className={classnames([
								        classes.scopeGuidanceHelpText,
							        ])}>
							        The Scope Prompt is the initial question or topic
							        you provide, setting the primary context for your
							        research / analysis with PUMA, the proprietary
							        assistant at Valuer. This prompt guides the angle of
							        your Scope and the retrieval of results from the
							        proprietary Valuer database, which include detailed
							        data relevant to your Scope’s inquiry. These
							        profiles may contain information on company business
							        models, technologies, products, and more, ensuring
							        the responses you receive are not only accurate but
							        also directly relevant to your specific needs.
						        </Typography>
					        </div>
					        <div>
						        <Button
							        onClick={() =>
								        (window.location.href = "/search-type/guided")
							        }
							        className={classnames([classes.promtAssistantBtn])}>
							        <PawIcon
								        className={classnames([
									        classes.scopeSelectBtnIcon,
								        ])}
							        />
							        Start Scope Assistant
						        </Button>
					        </div>
					        <div>
						        <Button
							        onClick={() =>
								        (window.location.href = "/search-type/free")
							        }
							        className={classnames([classes.inputByPromptBtn])}>
							        <ScopeIcon
								        className={classnames([
									        classes.scopeSelectBtnIcon,
								        ])}
							        />
							        Create Non-Assisted Scope
						        </Button>
					        </div>
				        </div>
			        </div>
		        )
	        }
	        {
				previewScopes && (
			        <div className={classnames([classes.previousScopesWrapper])}>
				        <div className={classnames([classes.previousScopesTitleWrapper])}>
					        <div className={classnames([classes.previousScopesTitle])}>Previous Scopes</div>
					        <div className={classnames([classes.previousScopesLegend])}>
						        <div className={classnames([classes.previousScopesScopeLegend])}><ScopeIcon style={{color: "#485E87"}}/> Scope</div>
						        <div className={classnames([classes.previousScopesLookalikeLegend])}><LookAlikeIcon style={{color: "#007A5E"}} /> Look-alike</div>
						        <div>
							        <Tooltip
								        title={
									        <Typography
										        color="white"
										        variant="body2"
										        className={classes.infoTooltipText}>
										        Some text
									        </Typography>
								        }
								        arrow
							        >
								        <QuestionMarkTooltip
									        className={classnames([classes.tooltip])}
								        />
							        </Tooltip>
						        </div>
					        </div>
				        </div>
				        <div>
					        <TableContainer className={classes.tableContainer} component={Paper} style={{ background: "95%", maxHeight: "95%", overflowY: 'auto' }}>
						        <Table stickyHeader className={classes.table} aria-label="simple table">
							        <TableHead className={classes.head}>
								        <TableRow>
									        <TableCell className={classes.header}>Date</TableCell>
									        <TableCell className={classes.header} align="right">Type</TableCell>
									        <TableCell className={classes.header} align="left">Scope</TableCell>
									        <TableCell className={classes.header} align="right">Delete</TableCell>
								        </TableRow>
							        </TableHead>
							        <LoadScopeDialog
								        open={showAreYouSureModal}
								        setOpen={setShowAreYouSureModal}
								        handleYesClick={loadScope}
							        />
							        <DeleteSearchDialog
								        open={openDeleteSearchModal}
								        setOpen={setOpenDeleteSearchModal}
								        handleYesClick={handleRemoveSearch}
								        selectedSearch={selectedScope}
							        />
							        <TableBody className={classes.tbody}>
								        {searches?.results && searches.results.length > 0 && searches.results.map((search) => (
									        <TableRow key={search.id} className={classes.card}>
										        <TableCell className={`date ${classes.date}`} component="th" scope="row">
											        <div style={{whiteSpace: "nowrap"}}>
												        {search.created_date}
											        </div>
											        <div style={{whiteSpace: "nowrap"}}>
												        {search.created_ago}
											        </div>
										        </TableCell>
										        <TableCell className={`type ${search?.scope === "Find look-alike" ? classes.lookalike : classes.searchScope} ${classes.type}`}  align="right">{search?.scope === "Find look-alike" ? <LookAlikeIcon /> : <ScopeIcon />}</TableCell>
										        
										        <Tooltip title={<div className={classes.tooltipTitle}>{search.description}</div>} arrow>
											        {/* <TableCell className={`scope ${classes.scope}`} align="left" onClick={() => handleClick(search)}>{search.description.length > 120 ? `${search.description.substring(0, 120)} ...` : search.description}</TableCell> */}
											        
											        <TableCell
												        className={`scope ${classes.tableScope}`}
												        align="left"
												        onClick={() => { search && setSelectedScope(search); setShowAreYouSureModal(true)}}>{search?.description.length > 120 ? `${search?.description.substring(0, 120)} ...` : search?.description}</TableCell>
										        </Tooltip>
										        <TableCell align="right">
											        <TrashIcon
												        className={`delete ${classes.delete}`}
												        onClick={() => {search && setSelectedScope(search);
															setOpenDeleteSearchModal(true)}
														}
											        /></TableCell>
									        </TableRow>
								        ))}
							        </TableBody>
						        </Table>
					        </TableContainer>
				        </div>
			        </div>
		        )
	        }
         
        </div>
    );
};

export default ScopeSelect;
