// import Echo from "laravel-echo";
// import Pusher from "pusher-js";

// window.pusher = Pusher;

// export class SocketService {
//     constructor(csrfToken) {
//         this.csrfToken = csrfToken;
//     }
//     getSocketConnection() {
//         const options = {
//             broadcaster: "pusher",
//             key: process.env.REACT_APP_PUSHER_KEY,
//             cluster: process.env.REACT_APP_PUSHER_CLUSTER,
//             // authEndpoint: process.env.REACT_APP_BROADCASTING_URL,
//             // encrypted: process.env.REACT_APP_WS_FORCE_TLS,
//             // auth: {
//             //     // withCredentials: true,
//             //     headers: {
//             //         "X-CSRF-TOKEN": this.csrfToken,
//             //     },
//             // },
//             wsHost: process.env.REACT_APP_WS_HOST,
//             wsPort: process.env.REACT_APP_WS_PORT,
//             wssPort: process.env.REACT_APP_WS_PORT,
//             forceTLS: process.env.REACT_APP_WS_FORCE_TLS,
//             enableStats: false,
//             enabledTransports: ["ws", "wss"],
//         };

//         if (!window.echo) {
//             window.echo = new Echo(options);
//         }
//     }

//     closeConnection() {
//         if (window.echo) window.echo.disconnect();
//     }
// }

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ NEW @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
import { ML_DD_STREAM_URL } from "../constants/endpoints";
export class SocketService {
    constructor() {
        if (!SocketService.instance) {
            this.socket = null;
            SocketService.instance = this;
        }

        return SocketService.instance;
    }

    getSocketConnection() {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            this.socket = new WebSocket(ML_DD_STREAM_URL);

            this.socket.onopen = () => {
                console.log("WebSocket connection established.");
            };

            this.socket.onclose = ev => {
                console.log("WebSocket connection closed.", ev);
            };

            this.socket.onerror = error => {
                console.error("WebSocket error:", error);
            };
        }
    }

    closeConnection() {
        if (this.socket) {
            this.socket.close();
        }
    }

    sendMessage(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error("WebSocket is not open. Cannot send message.");
        }
    }

    addMessageListener(callback) {
        if (this.socket) {
            this.socket.onmessage = event => {
                const message = JSON.parse(event.data);
                if (message.type === "ping") {
                    this.sendMessage({ type: "pong" });
                } else {
                    callback(message);
                }
            };
        }
    }
}

const instance = new SocketService();
