import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Typography from "../Typography/Typography";
import MlContent from "./component/MlContent";
import UserContent from "./component/UserContent";
import UserInput from "./component/UserInput";
import SuggestedPromts from "./component/SuggestedPromts";
import Button from "../Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./style.js";
import Tooltip from "@material-ui/core/Tooltip";
// Assets
import pumaLogoSrc from "../../assets/images/chat/pumaLogo.png";
import { ReactComponent as StopPuma } from "../../assets/icons/StopPUMA.svg";
import { ReactComponent as BtnCheckIcon } from "../../assets/images/chat/btnCheckIcon.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
    createUserConversationStatusSelector,
    getUserConversationStatusSelector,
    updateUserConversationStatusSelector,
    userConversationSelector,
    getPumaSummaryStatusSelector,
    getConversationHistoryStatusSelector,
} from "../../store/userConversation/selectors";
import { getConversationHistoryRequest } from "../../store/userConversation/requests";
import { fetchPumaSummaryRequest } from "../../store/userConversation/requests";
import useSelectedFiltersCounter from "./hooks/useSelectedFiltersCounter";
// Constants
import { useSocket } from "../../common/useSocket";
import { useAuthHook } from "../../common/useAuthHook";
import { actions as userConversationActions } from "../../store/userConversation/slice";
import { REQUEST_PENDING } from "../../constants/statuses.js";
import { useScopeGuidanceStore } from "../../store/scopeGuidance/store";
import { useChatIntro } from "../../common/useChatIntro.js";
import ChatIntroService from "../../api/chatIntro/chatIntroService.js";
import Markdown from "../Markdown/Markdown";

/**
 * MlChat component represents a chat interface for interacting with the PUMA (Valuer's Processing Unit for Multilayered Analysis) system.
 * It allows users to ask questions and receive responses related to search results and analysis.
 *
 * @component
 * @param {Object} contextObject - The context object containing information about the search context.
 * @param {string} contextName - The name of the search context.
 * @param {Array} selectedFilters - The selected filters for the search.
 * @param {Object} conversationContext - The conversation context.
 * @param {string} currentSearchId - The ID of the current search.
 * @param {boolean} resultsFetched - Indicates whether the search results have been fetched.
 * @returns {JSX.Element} The MlChat component.
 */
const MlChat = ({
    contextObject,
    contextName,
    selectedFilters,
    conversationContext,
    currentSearchId,
    resultsFetched,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const chatContentEl = useRef(null);
    const userConversationData = useSelector(userConversationSelector);
    const conversationHistoryStatus = useSelector(
        getConversationHistoryStatusSelector,
    );
    /* const pumaSummaryStatus = useSelector(getPumaSummaryStatusSelector);
    const userConversationStatus = useSelector(
        getUserConversationStatusSelector,
    ); */
    const updateUserConversationStatus = useSelector(
        updateUserConversationStatusSelector,
    );
    const createUserConversationStatus = useSelector(
        createUserConversationStatusSelector,
    );
    const [showLoading, setShowLoading] = useState(false);
    const [hasSummary, setHasSummary] = useState(null);
    const [scrollingDown, setScrollingDown] = useState(true);
    const prevScrollY = useRef(0);
    const [showSummaryLoading, setShowSummaryLoading] = useState(false);
    const [summaryHasErrorResponse, setSummaryHasErrorResponse] =
        useState(false);
    const [introText, setIntroText] = useState("");
    const [introId, setIntroId] = useState("");
    const [introSet, setIntroSet] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    // const [streamingMessage, setStreamingMessage] = useState(""); 
    const streamingMessage = useRef("");
    const counter = useRef(0);

    //workaround - updating the conversation_context when filtering results. Remove conversation_id to start new conversation so that the context gets updated
    const { selectedFiltersCount } = useSelectedFiltersCounter({
        selectedFilters,
    });

    const { user } = useAuthHook();
    const chatIntroService = new ChatIntroService();

    // TEMP formater before reworking based on the backend
    const formatAnswer = (answer) => {
        // Format headings
        const headingPattern = /^(#{1,4})\s(.+)$/gm;
        answer = answer.replace(headingPattern, (match, hashes, sentence) => {
            const hashCount = hashes.length;
            const fontSize = 23 - hashCount;
            return `<span style="font-size:${fontSize}px; font-weight:bold;">${sentence}</span>`;
        });
    
        // Format italic text
        const italicPattern = /\*(.+?)\*/gs;
        answer = answer.replace(italicPattern, '<i>$1</i>');
    
        // Format links into citations
        const linkPattern = /(((http|https):\/\/)|(www\.))[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[0-9]+)?(\/\S*)?/g;
        answer = answer.replace(linkPattern, (url) => {
            if (!/^https?:\/\//.test(url)) {
                url = 'http://' + url;
            }
            return `[<a href="${url}" target="_blank" class="quotation_symbol" rel="noopener noreferrer" onclick="window.open('${url}', '_blank');">\u{0201D}</a>]`;
        });
    
        // Format bold text
        answer = answer.replace(/\*\*(.+)\*\*/, '<b>$1</b>');
    
        return answer;
    }
    //Handle incoming messages
    const handleConversation = payload => {
        setScrollingDown(true);
        prevScrollY.current = 0;

        if(payload.type === "streaming-start") {
            streamingMessage.current = "";
        }
        if (payload.answer) {
            if (streamingMessage.current === '') setShowLoading(false);
            streamingMessage.current += payload.answer;
                dispatch(
                    userConversationActions.setUpdateUserConversationLastQuestion({
                        id: currentSearchId,
                        response: formatAnswer(streamingMessage.current),
                    }),
                );
        }
        // setScrollingDown(true);
        // prevScrollY.current = 0;
        // if (payload.summary_request) {
        //     setHasSummary(true);
        //     setShowSummaryLoading(false);
        //     setSummaryHasErrorResponse(payload.error);
        //     dispatch(
        //         userConversationActions.pushPumaSummary({
        //             id: payload.search_id,
        //             response: payload,
        //         }),
        //     );
        // } else {
        //     setShowLoading(false);
        //     dispatch(
        //         userConversationActions.setUpdateUserConversationLastQuestion({
        //             id: payload.search_id,
        //             response: payload,
        //         }),
        //     );
        // }
    };

    useEffect(() => {
        if (!contextObject || introSet) {
            return;
        }

        if (contextObject.intro_id) {
            chatIntroService
                .getExistingIntro(contextObject.intro_id)
                .then(data => {
                    setIntroText(data);
                });
        } else {
            async function consumeStream(
                llm_id,
                scope_guide_steps,
                scope_description,
            ) {
                if (introText !== "") {
                    setIntroText("");
                }
                for await (const chunk of chatIntroService.streamIntroText(
                    llm_id,
                    scope_guide_steps,
                    scope_description,
                )) {
                    if (introId !== chunk.intro_id) {
                        setIntroId(chunk.intro_id);
                    }
                    setIntroText(prev => prev + chunk.answer);
                }
            }

            const steps = useScopeGuidanceStore.getState().steps;
            let stepsObj = null;
            if (steps) {
                stepsObj = {
                    STEP_1: steps[0] || "empty",
                    STEP_2: steps[1] || "empty",
                    STEP_3: steps[2] || "enpty",
                    STEP_4: steps[3] || "empty",
                    STEP_5: steps[4] || "empty",
                };
            }

            consumeStream("gpt-4o-openai", stepsObj, contextObject.description);
        }

        setIntroSet(true);
    }, [contextObject]);

    useEffect(() => {
        if (!contextObject || !introId) {
            return;
        }

        chatIntroService.saveIntroId(contextObject.id, introId);
    }, [introId]);

    useSocket({
        // type: "PUMA_CHAT",
        // data: {
        //     userId: user.id,
        //     searchId: currentSearchId,
        // },
        callBack: handleConversation,
    });

    //Handel message before it is sent
    const onEnterPress = event => {
        setShowLoading(true);
        setScrollingDown(true);
        prevScrollY.current = 0;
    };

    useEffect(() => {
        if (chatContentEl) {
            chatContentEl.current.scrollTop =
                chatContentEl.current.scrollHeight;
        }
    }, [
        updateUserConversationStatus,
        createUserConversationStatus,
        conversationHistoryStatus,
    ]);

    useEffect(() => {
        if (currentSearchId) {
            dispatch(getConversationHistoryRequest({ id: currentSearchId }));
        }
    }, [currentSearchId]);

    const onGetSummaryClick = () => {
        //reset scroll
        setScrollingDown(true);
        prevScrollY.current = 0;

        let payload = {
            deep_dive_question:
                "Please give an overall estimation on how well the results reflect the needs of the query. Based on that, continue into detail on the following aspects. Discuss the most represented industries or sectors represented in the results. Discuss why they might be so well-represented. Identify the most prominent problems being addressed in each of these top industries represented in the results list. Explain how these problems are related to the industry they belong to. Discuss the most commonly occurring solutions, in their application of technologies and approaches – and how they address each of the previously identified problems. Identify respectively the most uncommon, innovative and paradigm shifting solutions to the result list’s most prominent problems. Discuss why these solutions are so unique and what potential benefits they offer over the currently more common or conventional solutions. Use examples of unique selling points for companies representing both common and uncommon solutions. Possibly include potential main use cases represented between the unique selling points highlighted. Identify the main areas or locations represented among the result entries. Conclude the analysis by summarising the main points discussed. Sort the answer naturally into paragraphs",
            conversation_context: contextObject.conversation_context,
            scope_description: contextObject.description,
            summary_request: true,
            search_id: contextObject.id,
        };
        if (contextObject && contextObject.conversation_id) {
            payload.conversation_id = contextObject.conversation_id;
        }
        setShowSummaryLoading(true);
        dispatch(
            fetchPumaSummaryRequest({ data: payload, id: contextObject.id }),
        );
    };

    const onScrollToSummaryClick = () => {
        if (chatContentEl) {
            const summaryAnswers = chatContentEl.current.querySelectorAll(
                '[is-summary-answer="true"]',
            );
            if (summaryAnswers.length) {
                summaryAnswers[summaryAnswers.length - 1].scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        }
    };

    //while typing is true always scroll
    useEffect(() => {
        if (
            scrollingDown &&
            chatContentEl.current &&
            contextObject &&
            userConversationData &&
            userConversationData[contextObject.id] &&
            userConversationData[contextObject.id].conversation.length &&
            userConversationData[contextObject.id].conversation[
                userConversationData[contextObject.id].conversation.length - 1
            ].typing
        ) {
            var interval = setInterval(() => {
                chatContentEl.current.scrollTop =
                    chatContentEl.current.scrollHeight;
            }, 10);
        }
        return () => clearInterval(interval);
    }, [contextObject, userConversationData, scrollingDown]);

    const stopTypingLastMsg = () => {
        if (
            userConversationData[contextObject.id] &&
            userConversationData[contextObject.id].conversation.length > 0
        ) {
            dispatch(
                userConversationActions.updateConversationTyping({
                    id: contextObject.id,
                    index:
                        userConversationData[contextObject.id].conversation
                            .length - 1,
                    typing: false,
                }),
            );
        }
    };

    const handleScroll = () => {
        const currentScrollY = chatContentEl.current.scrollTop;
        if (currentScrollY < prevScrollY.current) {
            setScrollingDown(false); // User is scrolling up
        }
        prevScrollY.current = currentScrollY;
    };

    return (
        <div className={classes.mlChat}>
            {/* Header */}
            <div className={classes.chatHeaderContainer}>
                <div></div>
                <div className={classes.header}>
                    {/* <div className={classes.pumaLogoWrapper}>
                        <img
                            src={pumaLogoSrc}
                            className={classes.pumaLogo}
                            alt="puma logo"
                        />
                    </div> */}
                    <Typography
                        variant="subtitleResult"
                        className={classes.headerTitle}>
                        PUMA <b>Deep dive</b>
                    </Typography>
                </div>
                <div className={classes.infoToolTip}>
                    <Tooltip
                        title={
                            <Typography
                                color="white"
                                variant="body2"
                                className={classes.infoTooltipText}>
                                Please enter any questions you may have to the
                                results of your search. Feel free to ask into
                                specifics on an individual company profile, or
                                request lists or overview summaries on either a
                                selection of companies, or on the application of
                                technologies, problems to solve, etc.
                            </Typography>
                        }
                        arrow>
                        <div className={classes.infoIcon}>?</div>
                    </Tooltip>
                </div>
            </div>
            {/* Conversation content */}
            <div
                className={classes.chatContent}
                ref={chatContentEl}
                onScroll={handleScroll}>
                {contextObject && (
                    <>
                        {/* Summary */}
                        <div className={classes.summaryContainer}>
                            {hasSummary &&
                            summaryHasErrorResponse === false &&
                            userConversationData[contextObject.id] &&
                            selectedFiltersCount === 0 ? (
                                <Button
                                    className={classnames([
                                        classes.getSummaryBtn,
                                        classes.scrollToSummaryBtn,
                                    ])}
                                    onClick={() => {
                                        onScrollToSummaryClick();
                                    }}
                                    loading={showSummaryLoading}
                                    variant="quaternary">
                                    Take me to my summary
                                </Button>
                            ) : (
                                <Button
                                    className={classes.getSummaryBtn}
                                    onClick={() => {
                                        onGetSummaryClick();
                                    }}
                                    loading={showSummaryLoading}
                                    variant="quaternary">
                                    Click here for a summary of your result
                                </Button>
                            )}
                        </div>
                        {/* Suggestions */}
                        <div className={classes.suggestions}>
                            <SuggestedPromts
                                contextObject={contextObject}
                                selectedFiltersCount={selectedFiltersCount}
                                showLoading={showLoading}
                                setShowLoading={setShowLoading}
                            />
                        </div>
                        {/* Placeholder */}
                        <div>
                            <MlContent>
                                <Markdown markdown_text={introText} />
                            </MlContent>
                        </div>
                        {userConversationData &&
                        userConversationData[contextObject.id] &&
                        userConversationData[contextObject.id].conversation
                            .length !== 0 ? (
                            <>
                                {/* conversation is not empty */}
                                {userConversationData[
                                    contextObject.id
                                ].conversation.map((entry, index) => (
                                    <div key={`conversation-content-${index}`}>
                                        {entry.question && (
                                            <UserContent
                                                text={entry.question}
                                            />
                                        )}
                                        {entry.answer && (
                                            <MlContent
                                                contentOptions={true}
                                                text={entry.answer}
                                                conversationIndex={index}
                                                contextObject={contextObject}
                                                conversationItem={entry}
                                            />
                                        )}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                {conversationHistoryStatus ===
                                    REQUEST_PENDING && (
                                    <div className={classes.chatHistoryLoading}>
                                        <CircularProgress
                                            size="1.3rem"
                                            style={{ color: "#8594AF" }}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                {/* Loading answer */}
                <div
                    className={classnames([
                        classes.chatLoading,
                        { [classes.showLoading]: showLoading },
                    ])}>
                    <MlContent>
                        <CircularProgress
                            size="1.3rem"
                            style={{ color: "#8594AF" }}
                        />
                    </MlContent>
                </div>
            </div>
            {/* User input - Textarea */}
            <div className={classes.chatInput}>
                <UserInput
                    contextObject={contextObject}
                    contextName={contextName}
                    selectedFiltersCount={selectedFiltersCount}
                    conversationContext={conversationContext}
                    setShowLoading={setShowLoading}
                    onEnterPress={onEnterPress}
                />
                <div className={classes.stopBtnWrapper}>
                    {!showLoading &&
                        contextObject &&
                        userConversationData[contextObject.id] &&
                        userConversationData[contextObject.id].conversation
                            .length !== 0 &&
                        userConversationData[contextObject.id].conversation[
                            userConversationData[contextObject.id].conversation
                                .length - 1
                        ].typing && (
                            <StopPuma
                                className={classes.stopPuma}
                                onClick={stopTypingLastMsg}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export default MlChat;
