import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./index.css";
import "./i18n";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import PrivateRoute from "./PrivateRoute";
import GrowSumo from "react-growsumojs";
import { hotjar } from "react-hotjar";
// Pages
import GetStarted from "./pages/SearchCompanies/GetStarted";
import Subscribe from "./pages/SearchCompanies/Subscribe";
import SearchType from "./pages/SearchCompanies/SearchType";
import AddMoreDetails from "./pages/SearchCompanies/AddMoreDetails";
import Industry from "./pages/SearchCompanies/Industry";
import ContentDeckClientView from "./pages/ContentDeck/ContentDeckClientView";
import IndustriesAdded from "./pages/SearchCompanies/IndustriesAdded";
import IndustriesFound from "./pages/SearchCompanies/IndustriesFound";
import Subsectors from "./pages/SearchCompanies/Subsectors";
import Summary from "./pages/SearchCompanies/Summary";
import SearchLoader from "./pages/SearchCompanies/SearchLoader";
import SignUp from "./pages/Authentication/SignUp";
import SignIn from "./pages/Authentication/SignIn";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Results from "./pages/Results/Results";
import Projects from "./pages/Projects/Projects";
import Orders from "./pages/Orders/Orders.js";
import Dashboard from "./pages/Dashboard/Dashboard";
import LikedCompanies from "./pages/LikedCompanies/LikedCompanies";
import LatestActivities from "./pages/LatestActivities/LatestActivities";
import ProjectProfile from "./pages/Projects/ProjectProfile/ProjectProfile";
import Industries from "./pages/Industries/Industries";
import IndustryProfile from "./pages/Industries/IndustryProfile/IndustryProfile";
import SavedSearches from "./pages/SavedSearches/SavedSearches";
import Layout from "./pages/Layout/Layout";
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings";
import NotFound from "./pages/Errors/NotFound/NotFound";
import CreditSummary from "./pages/CreditSummary/CreditSummary";
import MlResultShare from "./pages/Company/MlResultShare";
import VerifyEmail from "./pages/Authentication/VerifyEmail";
import MLFailure from "./pages/Errors/MLFailure/MLFailure";
import ScopeSelector from "./pages/SearchCompanies/ScopeSelector.js";
import AssistedScope from "./pages/SearchCompanies/AssistedScope.js";
import FreeScope from "./pages/SearchCompanies/FreeScope.js";

// Hooks
import { useAuthHook } from "./common/useAuthHook";
import { useCookiesHook } from "./common/useCookiesHook";
import { useIntercom } from "react-use-intercom";
// Redux
import { REQUEST_SUCCESS, REQUEST_FAILURE } from "./constants/statuses";
import {
    getLoginStatusSelector,
    getLogoutStatusSelector,
    getUserStatusSelector,
    getRegisterStatusSelector,
} from "./store/auth/selectors";
import TagManager from "react-gtm-module";
import Technologies from "./pages/Technologies/Technologies";
import TechnologiesProfile from "./pages/Technologies/TechnologiesProfile/TechnologiesProfile";
import CompanyProfile from "./pages/Company/CompanyProfile";
import WorkspaceListViewPreview from "./pages/WorkspaceListViewPreview/WorkspaceListPreview";
import DemoCompany from "./pages/DemoCompany/DemoCompany";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export default function App() {
    const { user } = useAuthHook();
    const getLoginStatus = useSelector(getLoginStatusSelector);
    const getLogoutStatus = useSelector(getLogoutStatusSelector);
    const getRegisterStatus = useSelector(getRegisterStatusSelector);
    const getUserStatus = useSelector(getUserStatusSelector);
    const { getCookieStatus } = useCookiesHook();
    const [sanctumCookieResponse, setSancutumCookieResponse] = useState(false);
    useEffect(() => {
        if (
            getCookieStatus === REQUEST_SUCCESS ||
            getCookieStatus === REQUEST_FAILURE
        ) {
            setSancutumCookieResponse(true);
        }
    }, [getCookieStatus]);
    //Intercom
    const { boot, update, shutdown } = useIntercom();
    if (
        process.env.REACT_APP_ENV === "prod" ||
        process.env.REACT_APP_ENV === "staging"
    ) {
        boot();
    }

    useEffect(() => {
        if (
            process.env.REACT_APP_ENV === "prod" ||
            process.env.REACT_APP_ENV === "staging"
        ) {
            if (
                (getLoginStatus === REQUEST_SUCCESS ||
                    getLoginStatus === REQUEST_FAILURE ||
                    getRegisterStatus === REQUEST_SUCCESS) &&
                user
            ) {
                //Intercom update user data
                update({
                    user_id: user.id,
                    name: user.name,
                    email: user.email,
                });
            }
        }
    }, [getLoginStatus, getRegisterStatus, getUserStatus, user, update]);

    useEffect(() => {
        if (
            process.env.REACT_APP_ENV === "prod" ||
            process.env.REACT_APP_ENV === "staging"
        ) {
            if (
                getLogoutStatus === REQUEST_SUCCESS ||
                getLogoutStatus === REQUEST_FAILURE
            ) {
                shutdown();
                boot();
            }
        }
    }, [getLogoutStatus, boot, shutdown]);

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "prod") {
            // HubSpot's analytics and tracking
            const script = document.createElement("script");
            script.src = "//js.hs-scripts.com/6768407.js";
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            //Google tag manager
            const tagManagerArgs = {
                gtmId: "GTM-5QTH8GN",
            };
            TagManager.initialize(tagManagerArgs);
            //HotJar
            hotjar.initialize(2885455, 6);
        }
    }, []);

    //PartnerStack
    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "local") {
            GrowSumo.initialize(process.env.REACT_APP_PARTNERSTACK_API_KEY);
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                {sanctumCookieResponse && (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Switch>
                            <PrivateRoute exact path="/deck/:slug/preview">
                                <Layout match="companies">
                                    <ContentDeckClientView />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/">
                                <GetStarted />
                            </PrivateRoute>
                            <PrivateRoute exact path="/subscribe">
                                <Subscribe />
                            </PrivateRoute>
                            <PrivateRoute exact path="/outcome">
                                <Redirect to="/search-type" />
                            </PrivateRoute>
                            {/*<PrivateRoute exact path="/search-type">*/}
                            {/*    <SearchType />*/}
                            {/*</PrivateRoute>*/}
                            {/*<PrivateRoute exact path="/description">*/}
                            {/*    <Description />*/}
                            {/*</PrivateRoute>*/}
                            {/*<PrivateRoute exact path="/search-look-alike">*/}
                            {/*    <SearchLookAlike />*/}
                            {/*</PrivateRoute>*/}
                            {/*<PrivateRoute exact path="/search-patent">*/}
                            {/*    <SearchPatent />*/}
                            {/*</PrivateRoute>*/}
                            <PrivateRoute exact path="/add-more-details">
                                <AddMoreDetails />
                            </PrivateRoute>
                            <PrivateRoute exact path="/industry">
                                <Industry />
                            </PrivateRoute>
                            <PrivateRoute exact path="/industries-added">
                                <IndustriesAdded />
                            </PrivateRoute>
                            <PrivateRoute exact path="/industries-found">
                                <IndustriesFound />
                            </PrivateRoute>
                            <PrivateRoute exact path="/subsectors">
                                <Subsectors />
                            </PrivateRoute>
                            <PrivateRoute exact path="/summary">
                                <Summary />
                            </PrivateRoute>
                            <PrivateRoute exact path="/search-results-loading">
                                <SearchLoader />
                            </PrivateRoute>
                            <Route exact path="/demo/report/clearblade">
                                <DemoCompany />
                            </Route>
                            <Route exact path="/sign-up">
                                <SignUp />
                            </Route>
                            <Route exact path="/sign-in">
                                <SignIn />
                            </Route>
                            <Route exact path="/forgot-password">
                                <ForgotPassword />
                            </Route>
                            <Route exact path="/password/reset">
                                <ResetPassword />
                            </Route>
                            <PrivateRoute exact path="/verification">
                                <VerifyEmail />
                            </PrivateRoute>
                            {/* All pages that have sidebar go inside of Layout */}
                            <PrivateRoute exact path="/search-type">
                                <Layout match="search-type">
                                    {/* <SearchType /> */}
                                    <ScopeSelector />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/search-type/guided">
                            <Layout match="search-type/guided">
                               <AssistedScope />
                            </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/search-type/free">
                            <Layout match="search-type/free">
                               <FreeScope />
                            </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/search-type/lookalike">
                                <Layout match="search-type/lookalike">
                                    <SearchType />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/results">
                                <Layout match="results" color="white">
                                    <Results />
                                </Layout>
                            </PrivateRoute>
                            <Route exact path="/share/search/:token">
                                <Layout match="results" color="white">
                                    <Results />
                                </Layout>
                            </Route>
                            <PrivateRoute exact path="/industries">
                                <Layout match="industries">
                                    <Industries />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/industries/:slug">
                                <Layout match="industries">
                                    <IndustryProfile />
                                </Layout>
                            </PrivateRoute>
                            <Route path="/companies/:slug">
                                <Layout match="companies">
                                    <CompanyProfile />
                                </Layout>
                            </Route>
                            <Route exact path="/share/company/:slug">
                                <Layout>
                                    <MlResultShare />
                                </Layout>
                            </Route>
                            <PrivateRoute exact path="/workspace">
                                <Projects />
                            </PrivateRoute>
                            {/* <PrivateRoute exact path="/dashboard">
                                <Layout match="dashboard">
                                    <Dashboard />
                                </Layout>
                            </PrivateRoute> */}
                            {/* <PrivateRoute exact path="/dashboard/lookalike">
                                <Layout match="dashboard/lookalike">
                                    <Dashboard />
                                </Layout>
                            </PrivateRoute> */}
                            <PrivateRoute exact path="/liked-companies">
                                <Layout>
                                    <LikedCompanies />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/latest-activities">
                                <Layout>
                                    <LatestActivities />
                                </Layout>
                            </PrivateRoute>
                            {/* <PrivateRoute exact path="/orders">
                                <Orders />
                            </PrivateRoute>
                            <PrivateRoute exact path="/orders/technologies">
                                <Orders />
                            </PrivateRoute> */}
                            <PrivateRoute exact path="/projects/:slug">
                                <Layout match="projects">
                                    <ProjectProfile />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/saved-searches">
                                <SavedSearches />
                            </PrivateRoute>
                            <PrivateRoute exact path="/profile-settings">
                                <ProfileSettings />
                            </PrivateRoute>
                            {/* <PrivateRoute exact path="/credit-summary">
                                <CreditSummary />
                            </PrivateRoute> */}
                            <PrivateRoute exact path="/ml-failure">
                                <MLFailure />
                            </PrivateRoute>
                            <PrivateRoute exact path="/ml-timeout">
                                <MLFailure type="timeout" />
                            </PrivateRoute>
                            <PrivateRoute exact path="/ml-company-not-found">
                                <MLFailure type="company-not-found" />
                            </PrivateRoute>
                            <PrivateRoute exact path="/technologies">
                                <Layout match="technologies">
                                    <Technologies />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/technologies/:slug">
                                <Layout match="technologies">
                                    <TechnologiesProfile />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute
                                exact
                                path="/technologies/:slug/companies">
                                <Layout match="technologies">
                                    <TechnologiesProfile />
                                </Layout>
                            </PrivateRoute>
                            <PrivateRoute exact path="/workspace-list-view">
                                <WorkspaceListViewPreview />
                            </PrivateRoute>
                            {/* preview links */}
                            <PrivateRoute exact path="/companies/enriched/deck">
                                <Layout match="companies">
                                    <CompanyProfile />
                                </Layout>
                            </PrivateRoute>
                            <Route exact path="/technologies/enriched/profile">
                                <Layout match="technologies">
                                    <TechnologiesProfile />
                                </Layout>
                            </Route>
                            {/* !preview links */}
                            <Route component={NotFound} />
                        </Switch>
                    </ThemeProvider>
                )}
            </Router>
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
}
