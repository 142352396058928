import { put, call } from "redux-saga/effects";
import { actions } from "../slice";

import {
    getUserConversationHelper,
    createUserConversationHelper,
    updateUserConversationHelper,
    saveUserConversationIdHelper,
    getPumaSummaryHelper,
    getConversationHistoryHelper
} from "./helpers";

function* getUserConversation(data) {
    try {
        yield put(actions.getUserConversationPending());
        const response = yield call(getUserConversationHelper, data);
        yield put(actions.setUserConversation(response));
        yield put(actions.setGetUserConversationSuccess());
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "prod") {
            console.log(err);
        }
        yield put(actions.setGetUserConversationFailure());
    }
}

function* createUserConversation(data, id) {
    console.log('create converation', data);
    if (
        data.hasOwnProperty("conversation_context") &&
        data.conversation_context === null
    ) {
        data.conversation_context = [];
    }
    try {
        yield put(actions.setCreateUserConversation({ data, id }));
        yield put(actions.setCreateUserConversationPending());
        const response = yield call(createUserConversationHelper, data);
        /* yield put(actions.setMlConversationId(response.data));
        yield put(
            actions.setUpdateUserConversationLastQuestion({ response: response.data, id }),
        ); */
        yield put(actions.setCreateUserConversationSuccess());
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "prod") {
            console.log(err);
        }
        yield put(actions.setCreateUserConversationFailure())
        yield put(actions.setSaveUserConversationIdFailure())
        /* yield put(actions.setUpdateUserConversationLastQuestion({
            response: {answer: "Our servers are busy. Please try again later."},
            id
        })); */
    }
}

function* updateUserConversation(data, id) {
    try {
        yield put(actions.pushNewQuestion({ data, id }));
        yield put(actions.setUpdateUserConversationPending());
        yield call(updateUserConversationHelper, data);
        /* yield put(
            actions.setUpdateUserConversationLastQuestion({ response: response.data, id }),
        ); */
        yield put(actions.setUpdateUserConversationSuccess());
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "prod") {
            console.log(err);
        }
        yield put(actions.setUpdateUserConversationFailure());
        /* yield put(
            actions.setUpdateUserConversationLastQuestion({
                response: {
                    answer: "Our servers are busy. Please try again later.",
                },
                id,
            }),
        ); */
    }
}

function* saveUserConversationId(data) {
    try {
        yield put(actions.setSaveUserConversationIdPending());
        yield call(saveUserConversationIdHelper, data);
        yield put(actions.setSaveUserConversationIdSuccess());
        yield put(actions.setCreateUserConversationUndefined());
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "prod") {
            console.log(err);
        }
        yield put(actions.setSaveUserConversationIdFailure());
        yield put(actions.setCreateUserConversationUndefined());
    }
}

function* emptyStorageConversation() {
    yield put(actions.emptyStorageConversation());
    yield put(actions.setCreateUserConversation(null));
    yield put(actions.setCreateUserConversationUndefined());
}

function* fetchPumaSummary(data, id) {
    try {
        yield put(actions.setGetPumaSummaryPending({ id }));
        const response = yield call(getPumaSummaryHelper, data);
        //yield put(actions.pushPumaSummary({ response: response.data, id }));
        yield put(actions.setGetPumaSummarySuccess({ id }));
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "prod") {
            console.log(err);
        }
        yield put(actions.setGetPumaSummaryFailure({ id }));
        /* yield put(
            actions.setUpdateUserConversationLastQuestion({
                answer: "Our servers are busy. Please try again later.",
            }),
        ); */
    }
}

function* getConversationHistory(id) {
    try {
        yield put(actions.setGetConversationHistoryPending());
        const response = yield call(getConversationHistoryHelper, id);
        yield put(actions.insertConversationHistory({ response, id }));
        yield put(actions.setGetConversationHistorySuccess());
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "prod") {
            console.log(err);
        }
        yield put(actions.setGetConversationHistoryFailure());
    }
}

export {
    getUserConversation,
    createUserConversation,
    updateUserConversation,
    saveUserConversationId,
    emptyStorageConversation,
    fetchPumaSummary,
    getConversationHistory
};
