import React, { useEffect, useState } from "react";
import { useStyles } from "../style";
import { useDispatch } from "react-redux";
import { actions as userConversationActions } from "../../../store/userConversation/slice";

const Typewriter = ({ text, conversationItem, conversationIndex, contextObjectId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [displayedText, setDisplayedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    //prevent flickering of full text and part of it after stopping typing
    useEffect(() => {
        if (!conversationItem.typing && text) {
            let textToDisplay = text;
            if (conversationItem.characterPosition !== null && conversationItem.characterPosition !== 0) {
                textToDisplay = text.substring(0, conversationItem.characterPosition);
            } 
            setDisplayedText(textToDisplay);
        }
    }, [conversationItem, text]);

    //animate typing
    useEffect(() => {
        const interval = setTimeout(() => {
            if (conversationItem.typing && currentIndex < text.length) {
                setDisplayedText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            } else {
                stopTyping(currentIndex);
            }          
        }, 3);

        return () => clearTimeout(interval);
    }, [currentIndex, text, conversationItem]);

    //stop typing and save the position of the last character
    const stopTyping = (currentIndex) => {
        dispatch(
            userConversationActions.updateConversationTyping({
                id: contextObjectId,
                index: conversationIndex,
                characterPosition: currentIndex,
                typing: false
            }),
        );
    };

    return (
        <>
            <div
                className={classes.typewriter}
                dangerouslySetInnerHTML={{ __html: displayedText }}>
            </div>
        </>
    );
};

export default Typewriter;
